.header {
  padding: 9rem 0 5rem 0;
  background-size: cover;
  background-position: center;
}

.header-box {
  white-space: nowrap;
  border: 9px solid var(--color-primary);
  width: 200px;
  padding: 7rem 2rem 2rem 2rem;
  margin: 0 2rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

@media all and (max-width: 620px) {
  .header-box {
    padding: 4rem 2rem 2rem 2rem;
    width: 150px;
  }
}

@media all and (max-width: 420px) {
  .header-box {
    padding: 4rem 1.25rem 2rem 1.25rem;
    width: 130px;
  }
}
