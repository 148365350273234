.contact-form {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin: 2rem 3rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.label {
  margin: 0 0 0.25rem 0.5rem;
}
