.nav {
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 0.25rem;
  background-color: var(--color-white);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
}

.nav-img {
  width: 3.5rem;
  padding-top: 0.25rem;
  margin: 0 1rem;
}

.nav-link,
.nav-btn {
  margin: 0 1rem;
  text-decoration: none;
  color: var(--color-black);
  font-weight: 600;
}

.nav-link:hover {
  opacity: 0.8;
}

@media all and (max-width: 500px) {
  .nav-link {
    display: none;
  }
}
