:root {
  --color-primary: #82e3e4;
  --color-white: #ffffff;
  --color-off-white: #fafafa;
  --color-grey: #e8e8e8;
  --color-grey-hover: #d1d1d1;
  --color-black: #222222;
  --color-primary-hover: #88f0f1;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
}

div {
  font-size: 14px;
}

ul {
  margin: 0;
  padding-left: 1.75rem;
}

hr {
  width: 300px;
  margin: 0 0 1rem 0;
}

input[type="text"],
input[type="email"],
textarea {
  width: 290px;
  height: 35px;
  background-color: var(--color-off-white);
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
}

.error,
label {
  width: 290px;
}

.error {
  color: red;
  text-align: center;
  font-weight: 600;
}

textarea {
  height: 60px;
  resize: none;
  padding: 0.5rem;
}

input[type="submit"] {
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
  border: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* Containers */

.container-1200,
.container-900,
.container-600 {
  margin: 0 auto;
}

.container-1200 {
  max-width: 1200px;
}

.container-900 {
  max-width: 900px;
}

.container-600 {
  max-width: 600px;
}

.container-100p {
  width: 100%;
}

/* Flexbox */

.row,
.row-center,
.row-space-between,
.row-space-around {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.row-center {
  justify-content: center;
}

.row-space-between {
  justify-content: space-between;
}

.row-space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

/* Positions */
.relative {
  position: relative;
}

/* Background */
.off-white-bg {
  background-color: var(--color-off-white);
}

.black-bg {
  background-color: var(--color-black);
}

/* Text */

.white-txt {
  color: var(--color-white);
}

.off-white-txt {
  color: var(--color-off-white);
}

.black-txt {
  color: var(--color-black);
}

.txt-center {
  text-align: center;
}

/* Test */

.border-test {
  border: 1px solid red;
}

@media all and (max-width: 420px) {
  label {
    width: 225px;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    width: 225px;
    height: 35px;
    background-color: var(--color-off-white);
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    padding: 0 0.5rem;
    font-family: "Poppins", sans-serif;
    color: var(--color-black);
  }
}
