.service-box,
.service-img {
  flex: 1 1;
  background-size: cover;
  background-position: center;
  padding: 2rem 2.5rem;
}

.service-img {
  height: 400px;
}

.service-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media all and (max-width: 810px) {
  .service-img {
    display: none;
  }

  .service-box {
    text-align: center;
  }
  .service-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-left: -1rem;
  }
}
