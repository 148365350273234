.contact {
  padding: 3rem 0;
  background-size: cover;
  background-position: center;
  background-color: var(--color-black);
}

.contact-text {
  width: 450px;
  margin: 3rem;
}

@media all and (max-width: 500px) {
  .contact-text {
    text-align: center;
  }
  hr {
    margin: 0 auto 1rem auto;
  }
}
