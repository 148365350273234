.about {
  padding: 4rem 1rem 5rem 1rem;
}

.about-container {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  padding-top: 2rem;
  margin: 0 auto;
}

.about-txt {
  width: 225px;
  padding: 1.5rem 1.25rem;
  z-index: 2;
  margin-top: 2.5rem;
}

.yrs-exp {
  line-height: 1.1;
  padding-left: 0.75rem;
}

.slideshow-shift {
  position: absolute;
  left: 225px;
  z-index: 1;
}

.grey-border {
  position: absolute;
  left: 315px;
  top: 5.5rem;
  width: 360px;
  height: 255px;
  border: 10px solid var(--color-white);
  opacity: 0.1;
}

@media all and (max-width: 810px) {
  .slideshow-shift {
    position: static;
  }

  .grey-border {
    display: none;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .about-txt {
    margin-bottom: 1.5rem;
  }
}

@media all and (max-width: 480px) {
  .slideshow-shift {
    position: static;
  }

  .grey-border {
    display: none;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .about-txt {
    margin-bottom: 1.5rem;
  }
}
