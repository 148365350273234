.default-slideshow {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.slide {
  object-fit: cover;
}

.circle-btn {
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 100vh;
  margin: 0.75rem 0.25rem;
  cursor: pointer;
}

.inactive {
  opacity: 0.5;
}

@media all and (min-width: 500px) {
  .slide {
    transition: 0.3s;
  }
}

@media all and (max-width: 500px) {
  .circle-btn {
    width: 15px;
    height: 15px;
    margin: 1rem 0.5rem;
  }
}
