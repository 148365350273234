.card,
.card-img,
.card-plain,
.card-message {
  width: 200px;
  height: 300px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.card-plain,
.card {
  margin: 1rem;
}

.card-plain {
  border: 10px solid var(--color-grey);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0.75rem;
}

.card-img {
  object-fit: cover;
  transition: 0.5s;
}

.card-img:hover {
  border: 10px solid var(--color-primary);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  opacity: 0.75;
}

.card-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85));
  color: var(--color-white);
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
  border: 10px solid var(--color-primary);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.15;
  padding: 0.75rem;
}

.card-message:hover {
  opacity: 1;
}

.card-title {
  line-height: 1.15;
  padding: 0.75rem 0;
}

@media all and (max-width: 450px) {
  .card-message {
    opacity: 1;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    border: 5px solid var(--color-grey);
  }

  .card-plain {
    border: 5px solid var(--color-grey);
  }

  .card,
  .card-img,
  .card-plain,
  .card-message {
    width: 275px;
    height: 275px;
  }
}
