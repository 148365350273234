.projects-row {
  display: flex;
  max-width: 1525px;
  margin: 0 auto 2rem;
  overflow-x: scroll;
}

/* width */
.projects-row::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
.projects-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
  border-radius: 100vh;
}

/* Handle */
.projects-row::-webkit-scrollbar-thumb {
  background: var(--color-grey);
  border-radius: 100vh;
}

/* Handle on hover */
.projects-row::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey-hover);
}

.square-card,
.square-card-img,
.square-card-plain,
.square-card-message {
  width: 350px;
  height: 350px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.square-card-plain,
.square-card {
  margin: 3rem 1rem 2rem 1rem;
}

.square-card-plain {
  border: 10px solid var(--color-grey);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0.75rem;
}

.square-card-img {
  object-fit: cover;
  transition: 0.5s;
}

.square-card-img:hover {
  border: 10px solid var(--color-primary);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  opacity: 0.75;
}

.square-card-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85));
  color: var(--color-white);
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
  border: 10px solid var(--color-primary);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.15;
  padding: 0.75rem;
}

.square-card-message:hover {
  opacity: 1;
}

.square-card-title {
  line-height: 1.15;
  padding: 0.75rem 0;
}

.square-card-subtitle {
  opacity: 0.8;
  padding: 0.5rem 0 1rem 0;
}

.square-btn {
  width: 150px;
}

@media all and (max-width: 450px) {
  .square-card,
  .square-card-img,
  .square-card-plain,
  .square-card-message {
    width: 275px;
    height: 275px;
  }
  .square-card-message {
    opacity: 1;
    border: 5px solid var(--color-primary);
    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)); */
  }
}
