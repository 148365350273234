.btn {
  display: inline-block;
  padding: 0.5rem 1.25rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  border: none;
  background: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.btn:hover {
  opacity: 0.9;
}

.blue-btn {
  color: var(--color-black);
  background-color: var(--color-primary);
}

.black-btn {
  color: var(--color-white);
  background-color: var(--color-black);
}
