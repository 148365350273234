/* General */
.semi-bold {
  font-weight: 500;
}
.bold {
  font-weight: 600;
}

/* Font Size */

.font-64-bold {
  font-size: 64px;
  line-height: 1.1;
}

.font-48-bold,
.font-48 {
  font-size: 48px;
}

.font-32-bold,
.font-32 {
  font-size: 32px;
}

.font-24-bold,
.font-24 {
  font-size: 24px;
}

.font-20-bold,
.font-20 {
  font-size: 20px;
}

.font-16-bold,
.font-16 {
  font-size: 16px;
}

.font-14-bold,
.font-14 {
  font-size: 14px;
}

/* Font Weight */

.font-64-bold,
.font-32-bold,
.font-24-bold,
.font-20-bold,
.font-16-bold,
.font-14-bold {
  font-weight: 600;
}

.font-32,
.font-24,
.font-20,
.font-16,
.font-14 {
  font-weight: 400;
}

@media all and (max-width: 620px) {
  .font-64-bold {
    font-size: 40px;
    line-height: 1.1;
  }

  .font-32-bold,
  .font-32 {
    font-size: 20px;
    line-height: 2;
  }

  .subtext {
    display: none;
  }
}

@media all and (max-width: 420px) {
  .font-64-bold {
    font-size: 30px;
    line-height: 1.1;
  }

  .font-32-bold,
  .font-32 {
    font-size: 16px;
    line-height: 2;
  }

  .subtext {
    display: none;
  }
}
